<template>
  <div>
    <b-card>
      <b-form-group>
        <v-select
          v-model="filter.year"
          :options="years"
          :reduce="(item) => item.name"
          placeholder="Tahun Ajaran"
          label="name"
          @input="handleChange"
        />
      </b-form-group>
    </b-card>
    <b-row>
      <b-col>
        <b-card title="Rata-rata IPK Mahasiswa">
          <pie-chart :api="api_gpa" :key="pie_key" />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Mahasiswa Aktif">
          <doughnut-chart :api="api_gpa" :key="doughnut_key" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <multiple-row-header-table
          :title="title"
          :items="getItems"
          :columns="columns"
          :totalRows="totalRows"
          :perPage="perPage"
          :currentPage="currentPage"
          :api="api"
          :filterCourse="true"
          :filterOn="false"
          @changeCurrentPage="changeCurrentPage"
          @changePerPage="changePerPage"
          :multiple_headers="headers"
          :key="table_key"
        ></multiple-row-header-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
} from "bootstrap-vue";
import LineChart from "@/components/LineChart.vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import MultipleColumnForm from "@/components/MultipleColumnForm.vue";
import table from "@/libs/table";
import PieChart from "@/components/PieChart.vue";
import DoughnutChart from "@/components/DoughnutChart.vue";
import MultipleRowHeaderTable from "@/components/MultipleRowHeaderTable.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    MultipleColumnForm,
    LineChart,
    PieChart,
    DoughnutChart,
    MultipleRowHeaderTable,
    vSelect,
  },
  data() {
    return {
      pie_key: 0,
      doughnut_key: 0,
      table_key: 0,
      api_gpa: "student-grades/dashboard",
      title: "IPK Mahasiswa",
      api: `student-grades/dashboard-table`,
      dir: false,
      items: [],
      filter: {
        year: null,
      },
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      years: [],
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "batch", label: "Tahun Akademik", sortable: true },
        { key: "calculateGrade.min", label: "Min", sortable: true },
        { key: "calculateGrade.average", label: "Rata - rata", sortable: true },
        { key: "calculateGrade.max", label: "Mak", sortable: true },
        { key: "accumulation.low", label: "< 3.00", sortable: true },
        { key: "accumulation.middle", label: "3.00 - 3.50", sortable: true },
        { key: "accumulation.top", label: "> 3.50", sortable: true },
      ],
      headers: [
        {
          key: "first",
          label: null,
          column_span: "2",
          class: "text-center",
        },
        {
          key: "second",
          label: "IPK Lulusan Reguler",
          column_span: "3",
          class: "text-center",
        },
        {
          key: "third",
          label: "Jumlah Lulusan Reguler dengan IPK",
          column_span: "3",
          class: "text-center",
        },
      ],
    };
  },
  async mounted() {
    const _ = this;
    const data = await this.getData();
    _.years = await this.getYears();
  },
  methods: {
    async getData() {
      const _ = this;
      let data;
      await _.$axios.get(_.api_gpa).then((res) => {
        data = res.data.data;
      });
      return data;
    },
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    async getYears() {
      const _ = this;
      let data;
      let result = [];
      await _.$axios.get("misc/years").then((res) => {
        data = res.data;
      });
      result = [...result, { name: "Semua" }];
      data.forEach((element) => {
        result = [...result, { name: element.year }];
      });
      return result;
    },
    handleChange() {
      this.api_gpa = this.filter.year == 'Semua' ? `student-grades/dashboard` :`student-grades/dashboard?angkatan=${this.filter.year}`;
      this.pie_key += 1;
      this.doughnut_key += 1;
      this.table_key += 1;
    },
  },
};
</script>